import React from 'react';
import './App.css';
import Intro from './Intro/Intro';
import TopBar from './TopBar/TopBar';
import Contact from './Contact/Contact';
import Background from './Background/Background';
import About from './About/About';
import Videos from './Videos/Videos';
import Map from './Map/Map';
import People from './People/People';
import Announcement from './Announcement/Announcement';
import HopeCHub from './HopeCHub/HopeCHub';
import HopeCafe from './HopeCafe/HopeCafe';
import Statement from './Announcement/Statement';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div>

        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/contact" element={
             <div>
             <Background/>
             <TopBar />
             <Contact/>
             </div>
          }>
          </Route>
          <Route path="/about" element={
             <div>
             <Background/>
             <TopBar />
             <About showMore={true}/>
             </div>
             }>
          </Route>
          <Route path="/hopecommunityhub" element={
            <div>
             <Background/>
             <TopBar />
             <HopeCHub showMore={true}/>
             </div>
             }>
            }>
          </Route>
          <Route path="/hopecafe" element={
            <div>
             <Background/>
             <TopBar />
             <HopeCafe showMore={true}/>
             </div>
             }>
          </Route>
          <Route path="/video" element={
            <div>
             <Background/>
             <TopBar />
             <Videos showMore={true}/>
             </div>
             }>
          </Route>
          <Route path="/people" element={
            <div>
             <Background/>
             <TopBar />
             <People showMore={true}/>
             </div>
             }>
          </Route>
          <Route path="*" element={
            <div>
             <Background/>
             <TopBar />
             <Intro />
             <Contact />
             <Announcement/>
             <About />
             <HopeCHub/>
             <HopeCafe/>
             <Map />
             <Videos />
             <People />
             </div>
             }>
          </Route>
         
        </Routes>
      </div>
    </Router>
      
  );
}

export default App;
