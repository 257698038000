import React, { useState } from 'react'
import axios from 'axios'
import './Contact.scss';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


export default () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null }
  })
  const [inputs, setInputs] = useState({
    email: '',
    message: ''
  })

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg }
      })
    } else {
      setStatus({
        info: { error: true, msg: msg }
      })
    }
  }
  const handleOnChange = e => {
    e.persist()
    setInputs(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }))
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null }
    })
  }
  const handleOnSubmit = e => {
    e.preventDefault()

    if (!inputs.message) {
        handleServerResponse(
            false,
            'Please type a message.'
          );
          return;
    }
    if (!inputs.email) {
        handleServerResponse(
            false,
            'Please enter your email.'
          );
          return;
    }
    if (!inputs.name) {
        handleServerResponse(
            false,
            'Please enter your name.'
          );
          return;
    }

    setStatus(prevStatus => ({ ...prevStatus, submitting: true }))
    axios({
      method: 'POST',
      url: 'https://formspree.io/xbjlgvwb',
      data: inputs
    })
      .then(response => {
        handleServerResponse(
          true,
          'Thank you, your message has been submitted.'
        )
      })
      .catch(error => {
        handleServerResponse(false, error.response.data.error)
      })
  }
  const name = '';
  return (
    <main>
      <form>
      <FormControl className='formControl_contact'>
        <TextField className='input_contact'
        label="Name" id="name" required variant="outlined" multiline
        onChange={handleOnChange}/>
        <br/>
        <TextField className='input_contact' id="email" 
        label="Email" type="email" name="_replyto" required variant="outlined" multiline
        onChange={handleOnChange}/>
        <br/>
        <TextField className='input_contact' id="message" 
            label="Message" multiline rows="5" 
            variant="outlined" name="message" required
            onChange={handleOnChange}/>
        <Button disabled={status.submitting} variant="contained"
         style={{position: 'absolute', top: '320px', width: '120px', right: '10%'}}onClick={handleOnSubmit}>
        {!status.submitting
            ? !status.submitted
              ? 'Submit'
              : 'Submitted'
            : 'Submitting...'}
        </Button>
      </FormControl>
      </form>

      {status.info.error && (
        <div style={{position: 'absolute', left: '20px', bottom: '-50px'}}>Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </main>
  )
}
