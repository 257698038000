import React, {Component} from 'react';
import './HopeCafe.scss';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';

export default class HopeCHub extends Component {

    state = {
        showMore: this.props.showMore == true
    }

    handleClick() {
        this.setState({showMore: !this.state.showMore});

        if (this.state.showMore) {
            document.getElementById('Hope Cafe').scrollIntoView();
        }
    }

    render() {
        return (
            <div className='root_cafe'>
                <div id="Hope Cafe" style={{position: 'relative', top: '-80px'}}></div>

                <div className='subTitle_cafe'><img src="hope_cafe.png" style={{textAlign: 'center', width: '90%'}}></img></div>
                

                <div className='aboutText_cafe' style={{display: this.state.showMore ? "block" : "none"}}>


    <div className='flowText_cafe'>

    <div className='blocks_cafe'>

    <div className='hopeCafe_cafe'>HOPE Café</div> is a fun and safe place where young people can come and chill out, build relationships with each other and receive support from positive adult role models. <br/>
    <br/>
    <div className='hopeCafe_cafe'>HOPE Café</div> is open one night a week on a Friday, for ages 9 to 14 years old.  <br/>
    19.00 to 21.00 (Summer and Spring) and 19.00 to 20.30 (Autumn and Winter)  <br/>
    Hot toasties, waffles, crumpets, chocolates, sweets, cans, and hot refreshments are available to purchase at very reasonable prices.  <br/>
    <br/>
    <div className='hopeCafe_cafe'>HOPE Café</div> is in the Hope Community Hub building, at the rear of New Life church on Shirehill, in Saffron Walden.  <br/>

    <div className='subTitle_cafe'>Aims</div>

    •	Providing positive adult role models, help young people to learn new skills and help them to make better lifestyle choices. <br/>
    •	Provide a place with a safe environment for young people to meet friends, have fun and engage in various activities to improve confidence and self-esteem. <br/>
    •	Partner with local church youth / children leaders, relevant agencies including the police, fire brigade and local council to provide resources that will help the young people make better lifestyle choices.  <br/>

    <div className='subTitle_cafe'>Activities</div>

    Including  games, crafts, workshops, dance, cookery, pool, table-tennis, film and disco nights, competitions as well as weekends away.   <br/>
     
    <div className='subTitle_cafe'>Long term goals</div>
    To help reduce anti-social behaviour,  build confidence and self-esteem as well as connect with God.<br/>
     
    <div className='subTitle_cafe'>Management</div>
    <div className='hopeCafe_cafe'>HOPE Café</div> is managed by the HOPE Community hub management committee and run by volunteers from New Life church and the local community.  Volunteers will be recruited, DBS-checked and trained.  <br/>
    <br/>
    For more information, please contact Rajiv Ellalasingham on 07761454251.<br/>


    </div>


                </div>
                </div>

            <div className='showMore_cafe'>
            <Tooltip title={this.state.showMore ? "Show Less" : "Read about Hope Café"}>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="default"
                onClick={this.handleClick.bind(this)}
                style={{ color: 'black',outline: 'none' }}
                size="large">
                {this.state.showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
            </Tooltip>
            </div>

            </div>
        );
    }
}
