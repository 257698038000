import React, {Component} from 'react';
import './Announcement.scss';
import textfile from './Announcement.md';
import ReactMarkdown from 'react-markdown'

function Notes(file) {
    const [text, setText] = React.useState();
    fetch(file)
      .then((response) => response.text())
      .then((textContent) => {
        setText(textContent);
      });
    return <ReactMarkdown children={text} /> || "Loading...";
  };

export default function Announcement() {
    
    return (

    <div className='root_announce'>

    <div className='aboutText_announce'>
    {Notes(textfile)}
    </div>

    </div>
    );
}
