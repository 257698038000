import React from 'react';
import style from './Map.scss';
import Iframe from 'react-iframe';

const Map = () => (
    <div className={style.root}>
        <div >
            <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2455.3182421011757!2d0.25105441559247443!3d52.019299880966756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d889d898460f17%3A0xff37d8e07814f0d6!2sNew%20Life%20Pentecostal%20Church!5e0!3m2!1sen!2suk!4v1573411024698!5m2!1sen!2suk"
            width="100%"
            height="450px"
            display="block"
            position="relative"
            className={style.frame}
            />
            
        </div>

    </div>
);
export default Map;
