import React from 'react';
import style from './Background.scss';

const Background = () =>{
    
    return (

        <div style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100%',
        backgroundImage: 'url("/mainhall1.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'}}>
        </div>

);}

export default Background;
