import React, {Component} from 'react';
import './HopeCHub.scss';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';

export default class HopeCHub extends Component {

    state = {
        showMore: this.props.showMore == true
    }

    handleClick() {
        this.setState({showMore: !this.state.showMore});

        if (this.state.showMore) {
            document.getElementById('Hope Community Hub').scrollIntoView();
        }
    }

    render() {
        return (
            <div className='root_hub'>
                <div id="Hope Community Hub" style={{position: 'relative', top: '-80px'}}></div>
                <div className='titleText_hub'>
                Hope Community Hub
                </div>
                <div className='subTitle_hub'>
                Hope Community Hub is a project at New Life Church.<br/><br/>
                <img src="hub/hub_logo.jpg" width="300px"></img>
                </div>

                <div className='aboutText_hub' style={{display: this.state.showMore ? "block" : "none"}}>


    <div className='flowText_hub'>

    <div className='blocks_hub'>
    <div className='subTitle_hub'>Aims</div>

    The aim of Hope Community Hub is to help transform the atmosphere of our town, supporting children, families and individuals in the community.  We will do this by supporting people in all forms of crisis through practical support opportunities and signposting. 
    </div>

    <div className='blocks_hub'>
    <div className='gallery_hub'>
    <figure className='gallery__item1_hub'>
    <img src="hub/img3.png" className='gallery__img_hub'></img>
    </figure>
    <figure className='gallery__item2_hub'>
    <img src="hub/img2.png" className='gallery__img_hub'></img>
    </figure>
    <figure className='gallery__item3_hub'>
    <img src="hub/img1.png" className='gallery__img_hub'></img>
    </figure>
    <figure className='gallery__item4_hub'>
    <img src="hub/img7.png" className='gallery__img_hub'></img>
    </figure>
    </div>
    </div>

    <div className='blocks_hub'>
    Our aims are clear...<br/>
    •	To tackle deprivation experienced by individuals, young people, single parents and families in our local community, helping to restore personal dignity.<br/>
    •	To support the most vulnerable within our communities, through 1-2-1 support and signposting <br/>
    •	To provide a safe and welcoming environment for individuals to grow and face the daily challenge of difficult situations.<br/>
    •	To provide holistic, integrated services and support that make a difference to the lives of those in our community and give them opportunity to reach their potential.<br/>
    </div>

    <div className='blocks_hub'>
    <div className='gallery2_hub'>
    <figure className='gallery2__item1_hub'>
    <img src="hub/img5.png" className='gallery__img_hub'></img>
    </figure>
    <figure className='gallery2__item2_hub'>
    <img src="hub/img6.png" className='gallery__img_hub'></img>
    </figure>
    </div>
    </div>

    <div className='blocks_hub'>
    <div className='subTitle_hub'>Projects</div>
    <b>Make Lunch</b> - Meals for school children during the school holidays. These are for children who receive free school meals during term time and for families who are facing financial hardship.  <br/>
    <br/>
    <div className='subTitle_hub'>Future Projects</div>
    <b>Hope Café</b> for young people aged 11 -  15<br/>
    <b>Hope Drop in Centre</b>
    </div>

    <div className='blocks_hub'>

    <div className='subTitle_hub'>HOPE Community Hub Constitution</div>
    <br/>
    1.	<b>Name</b> - The name of the Association is HOPE Community Hub.<br/><br/>
    2.	<b>The aims are</b> <br/>
    To tackle deprivation experienced by individuals, young people, single parents, and families in our local community, helping to restore personal dignity. To support the most vulnerable within our communities, through 1-2-1 support and signposting. To provide a safe and welcoming environment for individuals to grow and face the daily challenge of difficult situations. To provide help and support that make a difference to the lives of those in our community and give them opportunity to reach their potential.
    <br/><br/>
    3.	<b>Management Committee</b><br/>
    There will be a management Committee of at least 6 members and not more than 12 members. <br/>
    The members of the Committee will be appointed by New Life Pentecostal Church and will include the Pastor of the church. People who serve on the management committee do so on a voluntary basis. They may receive some financial recompense for expenses they incur while carrying out their duties.<br/>
    The Committee will meet no fewer than three times a year.<br/>
    <br/>
    4.	<b>Financial management</b> <br/>
    The management Committee will be responsible for the financial management of all income and expenditure.<br/>
    The Committee will <br/>
    a)	Appoint a suitable person as Honorary Treasurer.   <br/>
    b)	Open a bank account in the name of HOPE Community Hub through which all transactions must be passed. Receipts to be provided for any reimbursements. <br/>
    c)	All cheques must be signed by two members of the Committee, one of whom should be the Honorary Treasurer.<br/>
    d)	Require the Treasurer to maintain custody of the following records: <br/>
    Cash book, Bank statements, audited statement of accounts, paying in slips, and cheque books. <br/>
    e)	Require the Treasurer to maintain the Hubs main cash book and reconcile the balances with the bank statement and submit a statement of accounts at the end of the financial year.<br/>
    f)	Consider the financial implications of all projects and obtain approval from New Life church of amounts over £1000.<br/>
    <br/>
    5.	<b>Safeguarding policy:</b>  <br/>
    The management Committee will incorporate and uphold the Safeguarding  Policy of New Life Pentecostal church and apply all required checks required by the Policy. The Safeguarding officer/s of New Life church will be called upon to implement the necessary DBS checks prior to any appointments made<br/>
    <br/>
    6.	<b>Health and Safety:</b> <br/>
    The management Committee will ensure that all aspects of health and safety are carried out and adhered to in respect of all its activities.<br/>
    <br/>
    7.	<b>Insurance policy</b>  <br/>   
    The management Committee will ensure that all required insurance policies are valid and cover all its activities.     <br/>
    <br/>
    8.	<b>Amendment of Constitution</b><br/>
    This constitution can be amended at a meeting of the management Committee by a two thirds majority vote of those present and voting provided that written notice of the motion has been given at least one month in advance to all Committee members.  <br/>
    <br/>
    9.	<b>Rules</b>
    <br/>
    The Management Committee will have the power to make rules for the governing of the Hub’s activities and amend them at any Management Committee meeting without notice.    <br/>    
    <br/>
    </div>

    </div>
    <div className='flowText_hub'>

    <div className='blocks_hub'>
    <div className='subTitle_hub'>Management Committee</div>
    <br/>
    Chairperson -  <b>Lucy Wagener</b><br/>
    Secretary – <b>Caroline Humphries</b> <br/>
    Treasurer – <b>Dawn Dorrington</b> <br/>
    <b>Julie Bowler</b><br/>
    <b>Peter Bowler</b>    <br/>
    <b>Gary Marchent</b> <br/>
    <b>Rajiv Ellalasingham</b><br/>
    <br/>
    </div>

    <div className='blocks_hub'>
    <div className='subTitle_hub'>Make a Donation</div><br/>
    Paypal:<br/>
    <div style={{display: 'inline'}}>
    <form action="https://www.paypal.com/donate" method="post" target="_top">
    <input type="hidden" name="business" value="swhopecommunityhub@gmail.com" />
    <input type="hidden" name="item_name" value="Hope Community Hub" />
    <input type="hidden" name="currency_code" value="GBP" />
    <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
    </form>
    </div>
    <br/>
    <br/>
    The Hub Bank Account:<br/>
    <br/>
    Hope Community Association<br/>
    Sort Code 30-90-89<br/>
    Account Number 42316460<br/>
    <br/>
    </div>
                </div>
                </div>

            <div className='showMore_hub'>
            <Tooltip title={this.state.showMore ? "Show Less" : "Read about Hope Community Hub"}>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="default"
                onClick={this.handleClick.bind(this)}
                style={{ color: 'black',outline: 'none' }}
                size="large">
                {this.state.showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
            </Tooltip>
            </div>

            </div>
        );
    }
}
