import React from 'react';
import './Contact.scss';
import ContactForm from './ContactForm';

const Contact = () => (

    <div className='root_contact'>
            <div className='titleText_contact'>
            <div id="Contact" style={{position: 'relative', top: '-80px'}}></div>
            Contact
            <span className='subTitle_contact'><br></br>
            <p>9 Shire Hill </p>
            <p>Saffron Walden</p>
            <p>Essex</p>
            <p>CB11 3AQ</p>
            
             <img src="/phone_logo.png" style={{
                top: '350px', left: '5%',
                width: '100px',
                display: 'inline-block'
                }}></img>
            <div>
            
            <br/>Rajiv Ellalasingham<br/>01799 500429<br/>
            <a href="mailto:rajiv.singham@btinternet.com">rajiv.singham@btinternet.com</a><br/>
            </div>

            </span>
            </div>

            <div className='formContainer_contact'>
                <div className='formDiv_contact'>
                <ContactForm />
                </div>
            </div>

    </div>
);
export default Contact;
