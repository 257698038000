import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import './TopBar.css';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SpeakerIcon from '@mui/icons-material/Speaker';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import PeopleIcon from '@mui/icons-material/People';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalCafe from '@mui/icons-material/LocalCafe';

const menuItems = [
  {icon: <HomeIcon/>, name: 'Home'},
  {icon: <PhoneIcon/>, name: 'Contact'},
  {icon: <InfoIcon/>, name: 'About'},
  {icon: <FavoriteIcon/>, name: 'HopeCommunityHub'},
  {icon: <LocalCafe/>, name: 'HopeCafe'},
  {icon: <YouTubeIcon/>, name: 'Video'},
 // {icon: <SpeakerIcon/>, name: 'Audio'},
 // {icon: <AnnouncementIcon/>, name: 'Newsletter'},
  {icon: <PeopleIcon/>, name: 'People'},
];

const ITEM_HEIGHT = 48;

function MainMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItem = (item) => {
    window.open(`/${item}`,"_self")
  };

  return (
    <div>
      <IconButton
        className='menu'
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MenuIcon className='successIcon'/>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 250,
            backgroundColor: 'rgba(123, 39, 126, 0.95)',
            color: 'white'
          }
        }}
      >

      {menuItems.map((item, i) => {
        return (
        <MenuItem onClick={handleItem.bind(this, item.name)} key={i}>
          <span style={{paddingRight: '20px'}}>{item.icon}</span> {item.name}
        </MenuItem>);
      })}

      </Menu>
    </div>
  );
}

export default class TopBar extends Component {

    state = {
        className: 'topBar'
    }

  render() {
    return (
      <div className={this.state.className} style={{fontWeight: 'bold'}}>
          <p id="topTitle">NEW LIFE CHURCH SAFFRON WALDEN</p>
          <p id="smallTitle">NEW LIFE CHURCH</p>
          <div style={{
            position: 'absolute',
            fontSize: '15px',
            top: '25px',
            right: '40px',
            fontWeight: 'lighter'
            }}>

          <div style={{
            position: 'absolute',
            top: '-13px',
            right: '0px'
            }}>
            <MainMenu/>
          </div>
      
        </div>
      </div>
    );
  }
}
