import React from 'react';
import './Intro.scss';

const Intro = () =>{
    
    return (
    <div className='root_intro'>
        <div id="Home" style={{position: 'relative', top: '0px'}}></div>
      
        <div className='leftPane_intro'>

            <div className='tryPraying_intro'>
            <a id="img-link" href="http://www.trypraying.co.uk/" ><br/>
            <img id="img-link" src="/logo.png"/><br/>
            </a>
            </div>

        
        <div className='announcementPane_intro'>

            <div className='announcement_intro'>

                <a target="_blank"
                title="Follow Hope Community Hub, Make Lunch on Facebook"
                href="https://www.facebook.com/groups/2602689866710315/">
                    <img alt="Follow Hope Community Hub on Facebook" 
                    src="make_lunch.jpeg" width="100px"
                    border={0}/>
                
                <div style={{color: 'white', padding: '5px'}}>
                Hope Community Hub, Make Lunch
                </div>
                </a>
            </div>
        </div>

        </div>

        <div className='title_intro'>
        </div>
        <div className='titleText_intro'>
            <p className='titlePara_intro'>NEW LIFE</p>
            <p className='titlePara_intro'>CHURCH</p>
            <p className='titlePara_intro'>SAFFRON WALDEN</p>

            <span className='subTitle_intro'><br></br>
            Love God. Love People.
            </span>
            
        </div>

    </div>
);}

export default Intro;
