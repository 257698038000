import React from 'react';
import './People.scss';
import PeopleCard from './PeopleCard';
import Grid from '@mui/material/Grid';

const people = require('./people.json');

export default function People() {

    return (
        <div className='root_people'>

        <div className='header_people'>
        <div id="People" style={{position: 'relative', top: '-80px'}}></div>
            People
        </div>


          <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
      
                {people.map((data, i) => {
                    return (<PeopleCard
                        role={data.role}
                        name={data.name}
                        image={data.image}
                        phone={data.phone}
                        email={data.email}
                        key={i}
                    />);
                })}

            </Grid>
          </Grid>
         </Grid>
        
        </div>
    );};
