import React, {Component} from 'react';
import './About.scss';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import textfile from './About.md';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

export default class About extends Component {

    state = {
        showMore: this.props.showMore == true,
        text: ""
    }

    Notes(file) {
        fetch(file)
          .then((response) => response.text())
          .then((textContent) => {
            this.setState({text: textContent});
          });
      };

    handleClick() {
        this.setState({showMore: !this.state.showMore});

        if (this.state.showMore) {
            document.getElementById('About').scrollIntoView();
        }
    }


    render() {
        this.Notes(textfile);

        return (
            <div className='root_about'>

                <div className='titleText_about'>
                <div id="About" style={{position: 'relative', top: '-80px'}}></div>
                    About
                    </div>
                <div className='aboutText_about'>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={this.state.showMore ? this.state.text : "" }/>
                </div>

                <div className='showMore_about'>
            <Tooltip title={this.state.showMore ? "Show Less" : "Read More"}>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="default"
                onClick={this.handleClick.bind(this)}
                style={{ color: 'white' }}
                size="large">
                {this.state.showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
            </Tooltip>
            </div>

            </div>
        );
    }
}
