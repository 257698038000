import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import './People.scss';

export default function PeopleCard(props) {
  
  return (
    <div style={{height: 'auto'}}>
    <Card className='card_people'>

        <CardMedia
          className='media_people'
          image={props.image}
          title={props.name}
        />
        <CardContent >
          <Typography gutterBottom variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography gutterBottom variant="h6" component="h2">
            {props.role}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.phone} <br/>
            <a href={`mailto:${props.email}`}>{props.email}</a>
          </Typography>
        </CardContent>
 
    </Card>
    </div>
  );
}
