import React from 'react';
import YouTube from 'react-youtube';

export default function VideoCard(props) {
  
  const opts = {
    width: '300px',
    height: '280px',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0
    }
  };
  
  return (
    <div style={{margin: '20px', padding: '0px', width: '300px', height: '280px', boxShadow: '2px 2px 8px #1f1c1c'}}>
    <YouTube style={{padding: '0px', margin: '0px',}} videoId={props.videoId} opts={opts}/>
    </div>
  );
}
