import React, {Component} from 'react';
import './Videos.scss';
import VideoCard from './VideoCard';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';

const videos = require('./videoIndex.json');

const initialShow = 4; // The number of videos to show initially.


class Videos extends Component {
    
    state = {
        showMore: this.props.showMore == true
        }

    handleClick() {
        this.setState({showMore: !this.state.showMore});

        if (this.state.showMore) {
            document.getElementById('Video').scrollIntoView();
        }
    }

    render() {

    const {path, index, showMore} = this.state;

    return (
        <div className='root_video'>
        <div className='header_video'>
        <div id="Video" style={{position: 'relative', top: '-10px'}}></div>
      
        </div>


        <Grid container justifyContent="center" spacing={0} >

        {
            videos.map((data, i) => {
            if (!showMore && i >= initialShow) return;
            return (<VideoCard
                videoId={data.videoId}
            />);
            })
        }

        </Grid>

    {videos.length > 2 ?
        (<div className='showMore_video'>
        <Tooltip title={this.state.showMore ? "Show Less" : "Show More"}>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="default"
                onClick={this.handleClick.bind(this)}
                style={{ color: 'white' }}
                size="large">
                {this.state.showMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </IconButton>
            </Tooltip>
          </div>) : null
    }

        </div>
    );}
};

export default Videos
